import { Component, Inject, OnInit, inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export type SnackBarType = 'success' | 'error' | 'info' | 'warning' | '';
export type SnackBarMode = 'light' | 'dark';
export interface SnackBarData {
  customTitle?: string;
  message: string;
  type?: SnackBarType;
  icon?: string;
  isMatIcon?: boolean;
  mode?: SnackBarMode;
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: false,
})
export class SnackBarComponent implements OnInit {
  public snackBarRef = inject(MatSnackBarRef);

  protected message = 'This is a SnackBar';
  protected customTitle: string | undefined = 'Success';
  protected type: SnackBarType = 'success';
  protected icon = '';
  protected isMatIcon = false;
  protected mode: SnackBarMode = 'light';

  public constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

  public ngOnInit(): void {
    this.customTitle = this.data.customTitle;
    this.message = this.data.message || '';
    this.type = this.data.type || '';
    this.mode = this.data.mode || 'light';
    this.icon = this.data.icon || '';
    this.isMatIcon = this.data.isMatIcon || false;
  }

  public dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
